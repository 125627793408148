export default {
  geolocation: {
    lat: '19.3181481',
    lng: '-99.2778444',
    label: '01520 Álvaro Obregón',
  },
  price_rules: {
    grouping: ',',
    decimal: '.',
  },
}
