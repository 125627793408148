const duplicate = (something) => {
  switch (typeof something) {
    case 'string':
      return something
    case 'object':
      return Array.isArray(something) ? [...something] : { ...something }
    default:
      return null
  }
}

export default duplicate
