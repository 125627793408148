export default {
  geolocation: {
    lat: '41.89332',
    lng: '12.482932',
    label: '00135 Roma',
  },
  price_rules: {
    grouping: '.',
    decimal: ',',
  },
}
