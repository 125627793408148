const createFetchCache = (fetchFn, cache) => (url, params) => (
  new Promise((resolve, reject) => {
    const cachedResult = cache.get(url)
    if (cachedResult) {
      resolve(cachedResult)
      return
    }

    fetchFn(url, params)
      .then((result) => {
        cache.put(url, result)
        resolve(cache.get(url))
      })
      .catch(reject)
  })
)

export default createFetchCache
