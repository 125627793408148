const getItem = (name) => {
  const cookieString = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`)
  return cookieString ? cookieString[2] : null
}

const setItem = (name, value, days = 365) => {
  const today = new Date()
  today.setTime(today.getTime() + (24 * 60 * 60 * 1000 * days))
  document.cookie = `${name}=${value};path=/;expires=${today.toGMTString()};`
}

const removeItem = (name) => setItem(name, '', -1)

const clear = () => {
  const cookies = document.cookie.split(';')
  cookies.forEach((cookie) => {
    // eslint-disable-next-line no-unused-vars
    const [name, ...value] = cookie.split('=')
    // No need but usefull
    // value = value.join('=');

    removeItem(name)
  })
}

export default {
  getItem,
  setItem,
  removeItem,
  clear,
}
