/* eslint-disable camelcase */
import en_au from './en_au'
import en_nz from './en_nz'
import en_us from './en_us'
import es_es from './es_es'
import es_mx from './es_mx'
import fr_fr from './fr_fr'
import id_id from './id_id'
import it_it from './it_it'
import pt_br from './pt_br'

export {
  en_au,
  en_nz,
  en_us,
  es_es,
  es_mx,
  fr_fr,
  id_id,
  it_it,
  pt_br,
}
