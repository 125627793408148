import merge from 'lodash.merge'
import defaultI18nMap from './i18nMap'
import * as defaultI18nConsts from './consts'
import * as defaultI18nMessages from './messages'

class I18nUtils {
  constructor(mapObject = {}, constsObject = {}, messagesObject = {}) {
    this.i18nMap = merge({}, defaultI18nMap, mapObject)
    this.i18nConsts = merge({}, defaultI18nConsts, constsObject)
    this.i18nMessages = merge({}, defaultI18nMessages, messagesObject)
  }

  getI18nMap(language) {
    return this.i18nMap[language] || this.i18nMap.en_us
  }

  getI18nConsts(language) {
    return this.i18nConsts[language] || this.i18nConsts.en_us
  }

  getI18nMessages(language) {
    return this.i18nMessages[language] || this.i18nMessages.en_us
  }

  createI18nPrice(language, value) {
    const { price_rules: rules } = this.getI18nConsts(language)
    const [integer, decimal] = value.toString().split('.')
    const groupedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, rules.grouping)
    return (
      decimal
        ? `${groupedInteger}${rules.decimal}${decimal}`
        : groupedInteger
    )
  }
}

export default I18nUtils
