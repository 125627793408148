/* eslint-disable no-use-before-define, max-len */
import composeApiUrl from './composeApiUrl'
import spreadPropertiesUp from './spreadPropertiesUp'

// This will return false if the last thing in the string is a numeric id or an UUID
// flyers/123456 -> false
// flyers/123456/flyer_gibs -> true
// properties/5ae9890c-80b4-4fdc-befd-41dfac1f4849 -> false
// properties/5ae9890c-80b4-4fdc-befd-41dfac1f4849/flyers -> true
const isResourceCountable = (resource) => !resource.match(/(\d+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/)

const createFetchRestData = (fetchFn, urlOptions, fetchParams = {}, limit = 100) => {
  if (!fetchFn) throw new Error('fetchFn cannot be undefined')
  if (!urlOptions) throw new Error('urlOptions cannot be undefined')

  function singlePageFetchFn(options) {
    return fetchFn(composeApiUrl(options), fetchParams)
  }

  function multiPageFetchFn(options) {
    const { resource, params } = options

    const countOptions = {
      ...options,
      resource,
      params: {
        ...params,
        fields: {}, // Remove fields for the count call
      },
    }

    const countUrl = composeApiUrl({ ...countOptions, resource: `${resource}/count` })

    return new Promise((resolve, reject) => {
      fetchFn(countUrl, fetchParams)
        .then(({ metadata: { count } }) => {
          const maxPage = Math.ceil(count / limit)
          const promises = []
          for (let page = 1; page <= maxPage; page += 1) {
            promises.push(singlePageFetchFn({
              ...options,
              params: { ...params, page, limit },
            }))
          }

          Promise.all(promises)
            .then((allData) => {
              resolve({
                data: allData.reduce((_data, oneData) => [..._data, ...oneData.data], []),
              })
            })
            .catch(reject)
        })
        .catch(reject)
    })
  }

  async function fetchRestData({
    resource, params, wrap, dataOnly, oneResource = false, populate, paginate = true,
  }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const options = { resource, params, ...urlOptions }

      const isCountable = isResourceCountable(resource)

      const json = await (
        !oneResource && (paginate && isCountable)
          ? multiPageFetchFn
          : singlePageFetchFn
      )(options)

      // If there is no data inside the response, return
      if (!json.data) {
        if (dataOnly) return oneResource || !isCountable ? null : []
        return json
      }

      // This will remove the ModelName from CakePHP
      // Replace this with a forced `nowrap` param in queryString
      json.data = spreadPropertiesUp(json.data)

      // If oneResource is true or resource is not countable turn the response into a single element
      if (oneResource || !isCountable) {
        ([json.data] = json.data)
      }

      // This will try to populate nested resources, if there are any
      if (populate && json.data) {
        const populateFn = typeof populate === 'function' ? populate : (() => populate)

        const fetchNestedData = Array.isArray(json.data) ? fetchMultipleNestedData : fetchSingleNestedData

        await fetchNestedData(json.data, populateFn)
      }

      // Wrap all the response if needed
      if (wrap) json.data = { [wrap]: json.data }

      // Returns only the data or the metadata too
      return dataOnly ? json.data : json
    } catch (e) {
      throw e
    }
  }

  function fetchMultipleNestedData(records, populateFn) {
    const promises = []
    for (let index = 0; index < records.length; index += 1) {
      const promise = fetchSingleNestedData(records[index], populateFn)
      promises.push(promise)
    }
    return Promise.all(promises)
  }

  function fetchSingleNestedData(record, populateFn) {
    const promises = []
    const populateObject = populateFn(record)
    // eslint-disable-next-line no-restricted-syntax
    for (const populateKey in populateObject) {
      if (Object.prototype.hasOwnProperty.call(populateObject, populateKey)) {
        const promise = fetchRestData({
          ...populateObject[populateKey],
          dataOnly: true,
        }).then((data) => { record[populateKey] = data }) // eslint-disable-line no-param-reassign
        promises.push(promise)
      }
    }
    return Promise.all(promises)
  }

  return fetchRestData
}

export default createFetchRestData
