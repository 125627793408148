export default {
  geolocation: {
    lat: '40.712728',
    lng: '-74.006015',
    label: '10001 New York',
  },
  price_rules: {
    grouping: ',',
    decimal: '.',
  },
  dateFormat: 'MM-DD-YYYY',
}
