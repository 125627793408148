export default {
  geolocation: {
    lat: '48.864049',
    lng: '2.331053',
    label: '75001 Paris',
  },
  price_rules: {
    grouping: '.',
    decimal: ',',
  },
}
