import qs from 'qs'

const composeApiUrl = ({
  resource, params = {}, baseUrl, key,
}) => {
  const url = `${baseUrl}/${resource}.json`
  const queryString = qs.stringify({ key, ...params })
  return `${url}?${queryString}`
}

export default composeApiUrl
