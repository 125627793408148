export default {
  geolocation: {
    lat: '-36.852095',
    lng: '174.76318',
    label: '1010 Auckland',
  },
  price_rules: {
    grouping: ',',
    decimal: '.',
  },
}
