export default {
  geolocation: {
    lat: '-6.141068',
    lng: '106.694194',
    label: '11830 Jakarta',
  },
  price_rules: {
    grouping: '.',
    decimal: ',',
  },
}
