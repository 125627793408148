import cookies from './cookies'

const createWebStorage = () => {
  try {
    const testItem = {
      name: 'today',
      value: 'is a lucky day!',
    }

    // Try to achieve a dummy write into localStorage
    localStorage.setItem(testItem.name, testItem.value)
    if (localStorage.getItem(testItem.name) !== testItem.value) {
      throw new Error('localStorage is not consistent')
    }

    localStorage.removeItem(testItem.name)

    return localStorage
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Cannot use localStorage browser APIs, falling back to cookies')
    return cookies
  }
}

export default createWebStorage
