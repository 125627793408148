export default {
  geolocation: {
    lat: '-23.533773',
    lng: '-46.625290',
    label: '01001 São Paulo',
  },
  price_rules: {
    grouping: '.',
    decimal: ',',
  },
}
