export default {
  geolocation: {
    lat: '40.4167047',
    lng: '-3.7035825',
    label: '28013 Madrid',
  },
  price_rules: {
    grouping: '.',
    decimal: ',',
  },
}
