// https://it.wikipedia.org/wiki/ISO_639
// ftp://ftp.fu-berlin.de/doc/iso/iso3166-countrycodes.txt
export default {
  it_it: {
    ISO639: 'it',
    ISO3166_1: 'IT',
    ISO3166_2: 'ITA',
  },
  fr_fr: {
    ISO639: 'fr',
    ISO3166_1: 'FR',
    ISO3166_2: 'FRA',
  },
  es_es: {
    ISO639: 'es',
    ISO3166_1: 'ES',
    ISO3166_2: 'ESP',
  },
  es_mx: {
    ISO639: 'es',
    ISO3166_1: 'MX',
    ISO3166_2: 'MEX',
  },
  pt_br: {
    ISO639: 'pt',
    ISO3166_1: 'BR',
    ISO3166_2: 'BRA',
  },
  id_id: {
    ISO639: 'id',
    ISO3166_1: 'ID',
    ISO3166_2: 'IND',
  },
  en_au: {
    ISO639: 'en',
    ISO3166_1: 'AU',
    ISO3166_2: 'AUS',
  },
  en_nz: {
    ISO639: 'en',
    ISO3166_1: 'NZ',
    ISO3166_2: 'NZL',
  },
  en_us: {
    ISO639: 'en',
    ISO3166_1: 'US',
    ISO3166_2: 'USA',
  },
}
