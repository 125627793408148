/* eslint-disable no-console */
const { default: PQueue } = require('p-queue')

const defaultOptions = {
  concurrency: 10,
  failOnError: true,
  verbose: false,
}

const createFetchQueue = (
  fetchFn,
  options = {},
) => {
  const {
    concurrency,
    failOnError,
    verbose,
  } = { ...defaultOptions, ...options }
  const queue = new PQueue({ concurrency })

  const verboseLog = verbose ? console.log : () => false
  const verboseError = verbose ? console.error : () => false

  return (url, params) => (
    new Promise((resolve, reject) => {
      queue.add(() => {
        verboseLog(`Added call: ${url}`)
        return fetchFn(url, params)
          .then((response) => {
            verboseLog(`Resolved call: ${url}`)
            resolve(response)
          })
          .catch((e) => {
            verboseError(`Error during call: ${url}`, e)
            return failOnError ? reject(e) : resolve({})
          })
      })
    })
  )
}

export default createFetchQueue
