import duplicate from './duplicate'

class Cache {
  constructor() {
    this.records = {}
  }

  get(key) {
    return duplicate(this.records[key])
  }

  put(key, value) {
    this.records[key] = duplicate(value)
  }

  clear() {
    this.records = {}
  }

  size() {
    return Object.keys(this.records).length
  }
}

export default Cache
