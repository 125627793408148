export default {
  geolocation: {
    lat: '-33.854816',
    lng: '151.216454',
    label: '2000 Sydney',
  },
  price_rules: {
    grouping: ',',
    decimal: '.',
  },
}
