const spreadPropertiesUp = (something) => {
  const spreadUp = (object) => (
    Object
      .keys(object)
      .reduce((newObject, key) => (
        Object.prototype.hasOwnProperty.call(object, key)
          ? { ...newObject, ...object[key] }
          : newObject
      ), {})
  )

  if (Array.isArray(something)) return something.map((s) => spreadUp(s))
  return spreadUp(something)
}

export default spreadPropertiesUp
